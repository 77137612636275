import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import awards from "../../images/news-and-press/awards/awards.jpg"
import stewardship_forest from "../../images/news-and-press/awards/stewardship_forest.jpg"
import banner from "../../images/news-and-press/awards/banner.jpg"

const Awards = () => (
      <Layout>
            <SEO title="Awards" />
            <br />
            <h1>Awards</h1>
            <div class="row">
                  <div class="col-8"><p>By restoring the historic Baxter farm over the last two and a half years, history was saved.</p>
                        <p>Our hard work was recognized by the following organizations:</p>
                        <ul>
                              <li><strong>“SIA (Stewardship In Action)”</strong> King County, Water and Land Resources Division / Partnership for Rural King County</li>
                              <li><strong>“Conservation in Action,”</strong> Landowner Incentive Program</li>
                              <li><strong>“Backyard Wildlife Sanctuary Award,”</strong> Washington State Fish and Wildlife</li>
                              <li><strong>“Wildlife Habitat Certificate,”</strong> National Wildlife Federation</li>
                              <li><strong>“Backyard Conservationist Award,”</strong> United States Department of Agriculture</li>
                              <li><strong>“Rock Award,”</strong> King Conservation District</li>
                              <li><strong>“Salmon Safe,”</strong> Certified by Stewardship Partners</li>
                              <li><strong>“Conservation Farm Merit,”</strong> Washington State University, King County Agriculture, and King Conservation District</li>
                              <li><strong>“Heritage Barn Register,”</strong> Washington State lists the Baxter Barn</li>
                              <li><strong>“Wildlife Tree Project,”</strong> Washington State Fish and Wildlife, King Conservation District, and King County</li>
                              <li><strong>“FSC,”</strong> Forest Stewardship Council certification, Northwest Certified Forestry /Northwest Natural Resource Group</li>
                              <li><strong>“Forest Stewardship Award,”</strong> Washington State University and Washington State Department of Natural Resources. Collaborators: Washington Forest Stewardship, Snoqualmie Watershed Forum, King County, and King Conservation District.</li>
                              <li><strong>“Washington Forest Stewardship Certificate of Achievement,”</strong> Washington State University and Washington State Department of Natural Resources. Collaborators: Washington Forest Stewardship, Snoqualmie Watershed Forum, King County, and King Conservation District</li>
                              <li><strong>“Family Forest Legacy Tree,”</strong> Habitat for the future generations, Family Forest Foundation</li>
                              <li><strong>“A Destination in the Greenway,”</strong> Mountains to Sound Greenway</li>
                              <li><strong>“Wildlife Steward of the Year,”</strong> King Conservation District</li>
                              <li><strong>“Wildlife Steward of the Year,”</strong> Washington Association of Conservation Districts</li>
                              <li><strong>“Nature Explore Classroom,”</strong> Arbor Day Foundation, Dimensions Educational Research Foundation</li>
                        </ul>
                  </div>
                  <div class="col-2">
                        <img alt="Awards hanging on Baxter Barn shed" style={{margin: `25px`}} src={awards}></img>
                        <img alt="Stewardship Forest award being presented to Cory" style={{margin: `25px`}} src={stewardship_forest}></img>
                  </div>
            </div>
            <img alt="Awards hanging on Baxter Barn shed" style={{marginTop: `20px`, maxWidth: `100%`}} src={banner}></img>
      </Layout>
)

export default Awards
